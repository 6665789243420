import { authHeader } from "../helpers";
import { authHeaderPost } from "../helpers";
import { apiService } from "../services";
import axios from "axios";
import { sessionStore } from "@/stores/session";

export const userService = {
  login,
  logout,
  recuperaDadosUsuarioLogado,
  atualizaNomeAmigavelUsuarioLogado,
  atualizaEmailUsuarioLogado,
  atualizaFotoUsuarioLogado,
  alteraSenhaUsuarioLogado,
  recuperaRotasUsuario,
  enviarMensagemSuporte,
};

function login(username, password, conectado) {
  var postData = { Usuario: username, Senha: password };
  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postData),
  };

  return fetch(apiService.enderecoApiConta() + "/token", requestOptions)
    .then(apiService.handleResponse)
    .then((tokenResponse) => {
      if (conectado) {
        localStorage.setItem("token", JSON.stringify(tokenResponse));
      } else {
        sessionStorage.setItem("token", JSON.stringify(tokenResponse));
      }
      return tokenResponse;
    });
}

function recuperaDadosUsuarioLogado() {

  const tokenValor = sessionStore.tokenConsultaSuperbox || "";
  if (tokenValor == "") {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    return fetch(apiService.enderecoApiConta() + "/conta", requestOptions)
      .then(apiService.handleResponse)
      .then((user) => {
        return user;
      });
  } else {
    return Promise.resolve({});
  }
}

function atualizaNomeAmigavelUsuarioLogado(novoNome) {
  var postData = { NovoValor: novoNome };
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(postData),
  };

  return fetch(
    apiService.enderecoApiConta() + "/conta/nomeAmigavel",
    requestOptions
  )
    .then(apiService.handleResponse)
    .then((resultado) => {
      return resultado;
    });
}

function atualizaEmailUsuarioLogado(novoEmail) {
  var postData = { NovoValor: novoEmail };
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(postData),
  };

  return fetch(apiService.enderecoApiConta() + "/conta/email", requestOptions)
    .then(apiService.handleResponse)
    .then((resultado) => {
      return resultado;
    });
}

function atualizaFotoUsuarioLogado(novaFoto) {
  var postData = { NovoValor: novaFoto };
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(postData),
  };

  return fetch(apiService.enderecoApiConta() + "/conta/foto", requestOptions)
    .then(apiService.handleResponse)
    .then((resultado) => {
      return resultado;
    });
}

function alteraSenhaUsuarioLogado(senhaAtual, senhaNova) {
  var postData = { SenhaAtual: senhaAtual, SenhaNova: senhaNova };

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(postData),
  };

  return fetch(apiService.enderecoApiConta() + "/conta/senha", requestOptions)
    .then(apiService.handleResponse)
    .then((resultado) => {
      localStorage.setItem("token", JSON.stringify(resultado));
      return resultado;
    });
}

function enviarMensagemSuporte(motivo, mensagem) {
  var postData = { Motivo: motivo, TextoBase64: btoa(mensagem) };

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(postData),
  };

  return fetch(
    apiService.enderecoApiConta() + "/suporte/contato",
    requestOptions
  )
    .then(apiService.handleResponse)
    .then((resultado) => {
      return resultado;
    });
}

function logout() {
  localStorage.removeItem("token");
  sessionStorage.removeItem("token");
}

function recuperaRotasUsuario() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  // let url = "https://api3.outbox.com.br/conta/rotas"
  let url = apiService.enderecoApiConta() + "/conta/rotas";

  return fetch(url, requestOptions)
    .then(apiService.handleResponse)
    .then((user) => {
      return user;
    });
}
