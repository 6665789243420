export const apiService = {
  enderecoApi,
  enderecoApiConta,
  handleResponse,
  enderecoApiLotes,
  enderecoApiSkynet,
  handleErrorAxios,
  enderecoApiStaging,
  enderecoApiGestorBases,
  enderecoApiGestorBasesDev,
  enderecoApiChatOutbox,
  enderecoApiChatOutboxDev,
};

function enderecoApi() {
  return "https://api2.outbox.com.br";
  // return "https://api.outbox.com.br";
}

function enderecoApiStaging() {
  return "https://api1-staging.outbox.com.br";
}

function enderecoApiConta() {
  return "https://api-conta.outbox.com.br";
}

function enderecoApiLotes() {
  return "https://api-lotes.outbox.com.br";
}

function enderecoApiSkynet() {
  return "https://api-skynet.stepwise.com.br/";
}

function enderecoApiGestorBases() {
  return "https://api-gestorbases.outbox.com.br";
}

function enderecoApiGestorBasesDev() {
  return "https://outbox-dev-gestorbases-api1.azurewebsites.net";
}

function enderecoApiChatOutbox() {
  return "https://api-chat.outbox.com.br";
}
function enderecoApiChatOutboxDev() {
  return "https://outbox-chat-api1.azurewebsites.net/swagger/index.html";
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      var erroMsg =
        (data && data.message) ||
        (data && data.Message) ||
        response.statusText ||
        "Ops, ocorreu um erro.";
      if (location.pathname !== "/login") {
        window.alert(erroMsg);
      }

      var ipInvalido =
        "Ação proibida: Usuário está configurado para acessar a partir de outro(s) endereço(s) IP.";

      if (
        response.status === 401 ||
        (response.status === 403 && erroMsg == ipInvalido)
      ) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        sessionStorage.removeItem("token");

        if (location.pathname !== "/login") location.reload(true);
      }

      return Promise.reject(error);
    }

    return data;
  });
}

function handleErrorAxios(error) {
  if (location.pathname !== "/login") {
    var erroMsg =
      error.response.data.Message ||
      response.Message ||
      "Ops, ocorreu um erro.";
    window.alert(erroMsg);
  }

  var ipInvalido =
    "Ação proibida: Usuário está configurado para acessar a partir de outro(s) endereço(s) IP.";

  if (
    response.status === 401 ||
    (response.status === 403 && erroMsg == ipInvalido)
  ) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    sessionStorage.removeItem("token");

    if (location.pathname !== "/login") location.reload(true);
  }
  return error;
}
