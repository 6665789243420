import { query } from "vue-gtag";
import { authHeader } from "../helpers";
import { authHeaderPost } from "../helpers";
import { apiService } from ".";
import axios from "axios";

export const gestorHistoricoService = {
  recuperaHistoricoArquivos,
  recuperaHistoricoProjetos,
};

async function recuperaHistoricoArquivos(filtros) {
  var formData = new FormData();
  formData.append("HistoricoDe", filtros.HistoricoDe);
  formData.append("HistoricoAte", filtros.HistoricoAte);

  let url =
    apiService.enderecoApiGestorBases() + "/gestor_bases/historico/arquivos";

  let header = authHeaderPost(formData);

  return axios
    .post(url, formData, { headers: header })
    .catch((resultado) => {    
      const error = apiService.handleErrorAxios(resultado);
      throw new Error(error.response.data.Message);
    })
    .then((resultado) => {
      return resultado;
    });
}

async function recuperaHistoricoProjetos(filtros) {
  var formData = new FormData();
  formData.append("IdenditificadorProjeto", filtros.IdenditificadorProjeto);
  formData.append("HistoricoDe", filtros.HistoricoDe);
  formData.append("HistoricoAte", filtros.HistoricoAte);

  let url =
    apiService.enderecoApiGestorBases() + "/gestor_bases/historico/projetos";

  let header = authHeaderPost(formData);

  return axios
    .post(url, formData, { headers: header })
    .catch((resultado) => {
      const error = apiService.handleErrorAxios(resultado);
      throw new Error(error.response.data.Message);
    })
    .then((resultado) => {
      return resultado;
    });
}
