import { apiService } from "../services";
import { authHeader } from "../helpers";
import { sessionStore } from "@/stores/session";

export const mapasService = {
  recuperaBase64Mapa,
  recuperaCoordenadasPrincipal,
  recuperaCoordenadasEds,
  recuperaCoordenadasEfs,
  recuperaCoordenadasEap,
  recuperaCoordenadasDivisas,
  recuperaCoordenadasClassificacao,
  recuperaCoordenadasEmpresas,
  recuperaCoordenadasEmpresa,
  recuperaCoordenadasInundacoes,
};

function recuperaBase64Mapa(usuario, guid) {
  const requestOptions = {
    method: "GET",
  };

  var url =
    apiService.enderecoApi() +
    "/historico/mapa/agregador/v1" +
    "?usuario=" +
    usuario +
    "&codigotransacao=" +
    guid;

  return fetch(url, requestOptions)
    .then(apiService.handleResponse)
    .then((resultado) => {
      return resultado;
    });
}

function recuperaCoordenadasPrincipal(guid) {
  const tokenValor = sessionStore.tokenConsultaSuperbox;

  const requestOptions = {
    method: "GET",
    ...(tokenValor === "" ? { headers: authHeader() } : {}),
  };

  const camposRequest = {
    cod_agrupador: guid,
    tokenHistorico: tokenValor,
  };

  const queryString = new URLSearchParams(camposRequest).toString();

  const url = `${apiService.enderecoApi()}/historico/coordenadas/central?${queryString}`;

  return fetch(url, requestOptions)
    .then(apiService.handleResponse)
    .then((resultado) => {
      return resultado;
    });
}

function recuperaCoordenadasEds(guid) {
  const tokenValor = sessionStore.tokenConsultaSuperbox;

  const requestOptions = {
    method: "GET",
    ...(tokenValor === "" ? { headers: authHeader() } : {}),
  };

  const camposRequest = {
    cod_agrupador: guid,
    tokenHistorico: tokenValor,
  };

  const queryString = new URLSearchParams(camposRequest).toString();

  const url = `${apiService.enderecoApi()}/historico/poligonos/eds?${queryString}`;

  return fetch(url, requestOptions)
    .then(apiService.handleResponse)
    .then((resultado) => {
      return resultado;
    });
}

function recuperaCoordenadasEfs(guid) {
  const tokenValor = sessionStore.tokenConsultaSuperbox;

  const requestOptions = {
    method: "GET",
    ...(tokenValor === "" ? { headers: authHeader() } : {}),
  };

  const camposRequest = {
    cod_agrupador: guid,
    tokenHistorico: tokenValor,
  };

  const queryString = new URLSearchParams(camposRequest).toString();

  const url = `${apiService.enderecoApi()}/historico/coordenadas/efs?${queryString}`;

  return fetch(url, requestOptions)
    .then(apiService.handleResponse)
    .then((resultado) => {
      return resultado;
    });
}

function recuperaCoordenadasEap(guid) {
  const tokenValor = sessionStore.tokenConsultaSuperbox;

  const requestOptions = {
    method: "GET",
    ...(tokenValor === "" ? { headers: authHeader() } : {}),
  };

  const camposRequest = {
    cod_agrupador: guid,
    tokenHistorico: tokenValor,
  };

  const queryString = new URLSearchParams(camposRequest).toString();

  const url = `${apiService.enderecoApi()}/historico/coordenadas/eap?${queryString}`;

  return fetch(url, requestOptions)
    .then(apiService.handleResponse)
    .then((resultado) => {
      return resultado;
    });
}

function recuperaCoordenadasDivisas(guid) {
  const tokenValor = sessionStore.tokenConsultaSuperbox;

  const requestOptions = {
    method: "GET",
    ...(tokenValor === "" ? { headers: authHeader() } : {}),
  };

  const camposRequest = {
    cod_agrupador: guid,
    tokenHistorico: tokenValor,
  };

  const queryString = new URLSearchParams(camposRequest).toString();

  const url = `${apiService.enderecoApi()}/historico/poligonos/divisas?${queryString}`;

  return fetch(url, requestOptions)
    .then(apiService.handleResponse)
    .then((resultado) => {
      return resultado;
    });
}

function recuperaCoordenadasClassificacao(guid) {
  const tokenValor = sessionStore.tokenConsultaSuperbox;

  const requestOptions = {
    method: "GET",
    ...(tokenValor === "" ? { headers: authHeader() } : {}),
  };

  const camposRequest = {
    cod_agrupador: guid,
    tokenHistorico: tokenValor,
  };

  const queryString = new URLSearchParams(camposRequest).toString();

  const url = `${apiService.enderecoApi()}/historico/poligonos/classificacao?${queryString}`;

  return fetch(url, requestOptions)
    .then(apiService.handleResponse)
    .then((resultado) => {
      return resultado;
    });
}

function recuperaCoordenadasEmpresas(guid) {
  const tokenValor = sessionStore.tokenConsultaSuperbox;

  const requestOptions = {
    method: "GET",
    ...(tokenValor === "" ? { headers: authHeader() } : {}),
  };

  const camposRequest = {
    cod_agrupador: guid,
    tokenHistorico: tokenValor,
  };

  const queryString = new URLSearchParams(camposRequest).toString();

  const url = `${apiService.enderecoApi()}/historico/coordenadas/sociedades/empresas?${queryString}`;

  return fetch(url, requestOptions)
    .then(apiService.handleResponse)
    .then((resultado) => {
      return resultado;
    });
}
function recuperaCoordenadasEmpresa(guid) {
  const tokenValor = sessionStore.tokenConsultaSuperbox;

  const requestOptions = {
    method: "GET",
    ...(tokenValor === "" ? { headers: authHeader() } : {}),
  };

  const camposRequest = {
    cod_agrupador: guid,
    tokenHistorico: tokenValor,
  };

  const queryString = new URLSearchParams(camposRequest).toString();

  const url = `${apiService.enderecoApi()}/historico/coordenadas/empresa?${queryString}`;

  return fetch(url, requestOptions)
    .then(apiService.handleResponse)
    .then((resultado) => {
      return resultado;
    });
}

function recuperaCoordenadasInundacoes(guid) {
  const tokenValor = sessionStore.tokenConsultaSuperbox;

  const requestOptions = {
    method: "GET",
    ...(tokenValor === "" ? { headers: authHeader() } : {}),
  };

  const camposRequest = {
    cod_agrupador: guid,
    tokenHistorico: tokenValor,
  };

  const queryString = new URLSearchParams(camposRequest).toString();

  const url = `${apiService.enderecoApi()}/historico/poligonos/desastres/inundacoes?${queryString}`;

  return fetch(url, requestOptions)
    .then(apiService.handleResponse)
    .then((resultado) => {
      return resultado;
    });
}
